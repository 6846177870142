/* eslint react/jsx-no-bind: 0 */
import AddCoverageCard from '@root/quotes/src/components/coverage-options/add-coverage-card';
import BindParams from '@root/quotes/src/models/bind-params';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageCopy from '@root/quotes/src/components/coverage/coverage-copy';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import CoverageTitle from '@root/quotes/src/components/coverage/coverage-title';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import PipLimitsExplanationLinkModal from '@root/quotes/src/components/modals/pip-limits-explanation-link-modal';
import PipResidentRelativesWorkLoss from '@root/quotes/src/components/coverage/pip-resident-relatives-work-loss';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React, { Component, Fragment } from '@root/vendor/react';

export default class CoverageSectionNonLiability extends Component {
  static propTypes = {
    bindParams: PropTypes.instanceOf(BindParams).isRequired,
    cardHeaderBackgroundColor: PropTypes.string,
    coverageSelections: PropTypes.object,
    customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
    dropdownIndicator: PropTypes.string,
    IconComponent: PropTypes.elementType,
    mobileSelectedOptionStyles: PropTypes.object,
    onAcceptCoverages: PropTypes.func.isRequired,
    onCoverageChange: PropTypes.func.isRequired,
    onCoverageSelectClose: PropTypes.func.isRequired,
    onCoverageSelectOpen: PropTypes.func.isRequired,
    onDeclineCoverages: PropTypes.func.isRequired,
    onVehicleToggle: PropTypes.func.isRequired,
    primaryBrandColor: PropTypes.string,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    quoteCoveragesContext: PropTypes.instanceOf(QuoteCoveragesContext),
    quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
    renderLearnMore: PropTypes.func,
    secondaryBrandColor: PropTypes.string,
    styleOverrides: PropTypes.object,
  };

  static CoverageTypes = [
    Coverage.Symbols.MEDICAL_EXPENSES,
    Coverage.Symbols.WORK_LOSS,
    Coverage.Symbols.FUNERAL_BENEFITS,
    Coverage.Symbols.UNINSURED_MOTORIST,
    Coverage.Symbols.UNDERINSURED_MOTORIST,
    Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST,
    Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE,
    Coverage.Symbols.PERSONAL_INJURY_PROTECTION,
    Coverage.Symbols.MEDICAL_PAY,
  ]

  static HandleNoVehicleToggleTypes = [
    Coverage.Symbols.PERSONAL_INJURY_PROTECTION,
    Coverage.Symbols.MEDICAL_PAY,
  ]

  _handleAcceptCoverage = (coverage) => {
    if (!CoverageSectionNonLiability.HandleNoVehicleToggleTypes.includes(coverage.symbol)) {
      this.props.onAcceptCoverages([coverage]);
      return;
    }

    const allSelectedVehicles = this.props.profileParams.getAllSelectedVehicles();
    const allSelectedAvailableVins = allSelectedVehicles.map((v) => v.getAvailableVin());
    const coverageWithVins = coverage.set('coveredVins', allSelectedAvailableVins);

    this.props.onAcceptCoverages([coverageWithVins]);
  }

  render() {
    const {
      coverageSelections,
      customQuote,
      dropdownIndicator,
      IconComponent,
      mobileSelectedOptionStyles,
      onCoverageChange,
      onCoverageSelectClose,
      onCoverageSelectOpen,
      onDeclineCoverages,
      quotesContext,
      primaryBrandColor,
      secondaryBrandColor,
      styleOverrides,
    } = this.props;
    const coverages = customQuote.getOrderedCoveragesWithOptionsBySymbols(CoverageSectionNonLiability.CoverageTypes, coverageSelections);
    const coverageOptionsStackedAccepted = {
      ...Coverage.DefaultCoverageOptions,
      [Coverage.CoverageOptions.STACKED]: Coverage.CoverageOptionChoice.YES,
    };

    return (
      <CoverageGroup
        heading={'Non-Liability coverage'}
        styleOverrides={styleOverrides}
      >
        {coverages.map((coverage) => {
          const symbol = coverage.symbol;
          let onRemoveCoverage = null;

          if (customQuote.coverageIsDeclinable(coverage)) {
            onRemoveCoverage = () => onDeclineCoverages([coverage]);
          }

          const shouldDisplayPipResidentRelativesWorkLoss = coverage.symbol === Coverage.Symbols.PERSONAL_INJURY_PROTECTION &&
            coverageSelections.pip.some((selection) =>
              Coverage.isCoverageOptionAccepted(selection.coverageOptions, Coverage.CoverageOptions.RESIDENT_RELATIVES_INCLUDED)
            || Coverage.isCoverageOptionAccepted(selection.coverageOptions, Coverage.CoverageOptions.WORK_LOSS_EXCLUDED));

          let pipLimitsLink = null;
          if (shouldDisplayPipResidentRelativesWorkLoss) {
            pipLimitsLink = (
              <PipLimitsExplanationLinkModal
                primaryBrandColor={primaryBrandColor}
                secondaryBrandColor={secondaryBrandColor}
              />
            );
          }

          return (
            <Fragment key={symbol}>
              <AddCoverageCard
                description={
                  <CoverageCopy
                    coverage={coverage}
                    market={quotesContext.market}
                  />}
                heading={
                  <CoverageTitle
                    coverage={coverage}
                    market={quotesContext.market}
                  />}
                IconComponent={IconComponent}
                isCoverageAdded={!coverage.declined}
                onAddCoverage={() => this._handleAcceptCoverage(coverage)}
                primaryBrandColor={primaryBrandColor}
              />
              <CoverageSelectionCard
                description={
                  <CoverageCopy
                    additionalLink={pipLimitsLink}
                    coverage={coverage}
                    market={quotesContext.market}
                  />}
                headerBackgroundColor={this.props.cardHeaderBackgroundColor}
                heading={
                  <CoverageTitle
                    coverage={coverage}
                    market={quotesContext.market}
                  />}
                isCoverageAdded={!coverage.declined}
                onRemoveCoverage={onRemoveCoverage}
                primaryBrandColor={primaryBrandColor}
                renderLearnMore={() => this.props.renderLearnMore(coverageSelections, coverage, coverageOptionsStackedAccepted)}
                vehiclesSelected={true}
              >
                {
                  shouldDisplayPipResidentRelativesWorkLoss ?
                    <PipResidentRelativesWorkLoss
                      coverage={coverage}
                      coverageSelections={coverageSelections}
                      dropdownIndicator={dropdownIndicator}
                      mobileSelectedOptionStyles={mobileSelectedOptionStyles}
                      onClose={onCoverageSelectClose}
                      onCoverageChange={onCoverageChange}
                      onOpen={onCoverageSelectOpen}
                      pipCoverages={customQuote.quoteCoveragesContext.coverages.pip}
                      primaryBrandColor={primaryBrandColor}
                      secondaryBrandColor={secondaryBrandColor}
                    /> :
                    <QuotesSelect
                      dropdownIndicator={dropdownIndicator}
                      dropdownIndicatorColor={primaryBrandColor}
                      inputId={`${symbol}-coverage-select`}
                      inputName={`${symbol}-coverage-select`}
                      mobileSelectedOptionStyles={mobileSelectedOptionStyles}
                      onChange={(id) => onCoverageChange(id, symbol)}
                      onClose={onCoverageSelectClose}
                      onOpen={onCoverageSelectOpen}
                      options={coverageSelections[symbol]}
                      optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
                      placeholder={'Select option'}
                      selectedValue={coverage.id}
                    />
                }
              </CoverageSelectionCard>
            </Fragment>
          );
        })}
      </CoverageGroup>
    );
  }
}
